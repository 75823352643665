import React, { useState, useCallback } from 'react';
import {
    required, Create, ReferenceInput, AutocompleteInput,
    SimpleForm, TextInput, DateInput, BooleanInput, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Drawer } from '@material-ui/core';
import { useStyles } from "../../../formStyles";
import EmployeeLookup from './EmployeeLookup';

const myStyles = makeStyles(theme => ({
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: "90%"
    },
}));

const AllotmentCreate = props => {
    const classes = useStyles({});
    const myclasses = myStyles({});
    const [isAddArea, setIsAddArea] = useState(false);

    const closeEdit = () => {
        setIsAddArea(false);
    };

    const handleClose = useCallback(() => {
        setIsAddArea(false);
    }, []);

    const initialValues = {
        calimOrdId: props.calimOrdId,
        otherDetails: "",
        isInsured: false,
        isHospitalised: false,
        allotedOn: moment().toISOString(),
    };

    const handleSubmit = (formData) => {
        let userId = localStorage.getItem("userId");

        let data = {
            calimOrdId: parseInt(formData.calimOrdId),
            employeeId: formData.employeeId,
            otherDetails: formData.otherDetails,
            isInsured: formData.isInsured,
            isHospitalised: formData.isHospitalised,
            iscompleted: formData.iscompleted,
            isReallotment1: formData.IsReallotment1,
            reallot1Date: formData.Reallot1Date ? moment(formData.Reallot1Date).toISOString() : null,
            reallot1Reason: formData.Reallot1Reason,
            isReallotment2: formData.IsReallotment2,
            reallot2Date: formData.Reallot2Date ? moment(formData.Reallot2Date).toISOString() : null,
            reallot2Reason: formData.Reallot2Reason,
            isVerified: false,
            isDocCopyReceived: false,
            foVerifyRemarks: "",
            createdDate: moment().toISOString(),
            createdBy: parseInt(userId),
            allotedOn: moment(formData.allotedOn).toISOString()
        };
        data = Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== null)); 
        apiClient.addEntiyRecord("claim-ord-allots", data).then(() => {
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <>
            <Create {...props}>
                <SimpleForm
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                    variant="outlined"
                >
                    <DateInput disabled label="Alloted On" source="allotedOn" validate={[required()]} fullWidth formClassName={classes.first_inline_input} />

                    <FormDataConsumer fullWidth formClassName={classes.last_inline_input} >
                        {formDataProps => (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <ReferenceInput
                                    perPage={25}
                                    sort={{ field: 'name', order: 'ASC' }}
                                    variant="outlined"
                                    filterToQuery={searchText => ({ 'name~like': searchText })}
                                    validate={[required()]}
                                    label="Employee Name"
                                    source="employeeId"
                                    reference="employees"
                                    fullWidth
                                >
                                    <AutocompleteInput optionText="name" shouldRenderSuggestions={val => val && val.trim().length >= 1} />
                                </ReferenceInput>
                                <Button onClick={() => setIsAddArea(true)} style={{ marginLeft: '10px' }}>Lookup</Button>

                                <Drawer
                                    anchor="right"
                                    onClose={handleClose}
                                    classes={{ paper: myclasses.drawerPaper }}
                                    open={isAddArea}
                                >
                                    <div>
                                    {isAddArea && (
                                        <EmployeeLookup
                                            onClose={closeEdit}
                                            onCancel={handleClose}
                                            {...formDataProps}
                                            onSuccess={(item) => {
                                                if (item && item.id) {
                                                    debugger
                                                    formDataProps.formData.employeeId=item.id;
                                                }
                                                setIsAddArea(false);
                                            }}
                                            {...props}
                                        />
                                    )}</div>
                                </Drawer>
                            </div>
                        )}
                    </FormDataConsumer>

                    <TextInput source="otherDetails" label="Remarks" fullWidth />
                    <BooleanInput source="isInsured" label="Insured Part" fullWidth />
                    <BooleanInput source="isHospitalised" label="Hospital Part" fullWidth />
                    <BooleanInput source="iscompleted" label="Whole Part" fullWidth />

                    <BooleanInput label="Reallotment 1" source="IsReallotment1" fullWidth />
                    <DateInput label="Reallotment 1 Date" source="Reallot1Date" fullWidth />
                    <TextInput label="Reallotment 1 Reason" multiline source="Reallot1Reason" fullWidth />

                    <BooleanInput label="Reallotment 2" source="IsReallotment2" fullWidth />
                    <DateInput label="Reallotment 2 Date" source="Reallot2Date" fullWidth />
                    <TextInput label="Reallotment 2 Reason" multiline source="Reallot2Reason" fullWidth />

                </SimpleForm>
            </Create>
        </>
    );
};

export default AllotmentCreate;
