import React, { useState, useCallback } from 'react';
import { Drawer } from '@material-ui/core';
import {
  Edit, TabbedForm, TextInput, DateInput, ReferenceInput, SelectInput, required, BooleanInput, FormTab, useRefresh, ReferenceManyField, ReferenceField, Datagrid, NumberInput, TextField,
  DateField
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import { useStyles } from "../../formStyles";
import VSDeleteButton from "../../VSDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import ConfigCreate from './ConfigCreate'
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import DocumentsCreate from './DocumentsCreate';
import CircularDocumentsCreate from './CircularDocumentsCreate';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 650,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}
export const InsurerEdit = (props) => {
  const classes = useStyles({});
  const myclasses = myStyles({});
  const refresh = useRefresh();
  const [isaddTpa, setIsAddTpa] = useState(false);
  const [isaddDocuments, setIsAddDocuments] = useState(false);
  const [isaddCircularDocuments, setIsAddCircularDocuments] = useState(false);
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const closeEdit = () => {
    setIsAddTpa(false);
    setIsAddDocuments(false);
    setIsAddCircularDocuments(false);
    refresh();
  }
  const onCancel = () => {
    setIsAddTpa(false)
    setIsAddDocuments(false);
    setIsAddCircularDocuments(false);
  }
  const handleClose = useCallback(() => {
    setIsAddTpa(false);
    setIsAddDocuments(false);
    setIsAddCircularDocuments(false);
    //setIsAccountServiceAdd(false);
  }, []);
  return (
    <React.Fragment>
      <Edit title="Insurer Edit" {...propsObj}>


        <TabbedForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...propsObj} />}>
          <FormTab label="Insurer Detail ">

            <TextInput source="code" label="Code" validate={[required()]} formClassName={classes.one_three_input} disabled={true} />
            <DateInput source="dealDate" label="Ent. Date" validate={[required()]} formClassName={classes.two_three_input} />
            <DateInput source="openDate" label="Open Date" validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput source="name" label="Name" validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="phoffice" label="Phone(Off)." formClassName={classes.two_three_input} />
            <TextInput source="email" label="email" formClassName={classes.last_three_input} />


            <TextInput source="phmobile" label="Mobile" formClassName={classes.one_three_input} />
            <TextInput source="tin" label="Tin" formClassName={classes.two_three_input} />
            <TextInput source="cstNo" label="CST Number" formClassName={classes.last_three_input} />

            <TextInput source="tpt" label="tpt" formClassName={classes.one_three_input} />
            <TextInput source="licno" label="LIC No" formClassName={classes.two_three_input} />
            <TextInput source="panno" label="Pan Number" formClassName={classes.last_three_input} />
            <div style={{ width: "100%" }} ><h4>Billing Address Detail</h4></div>
            <div style={{ width: "100%" }}><hr /></div>

            <TextInput source="state" label="Bill State" validate={[required()]} formClassName={classes.one_three_input} />
            <ReferenceInput label="Bill  Area" source="areaId" reference="areas"  perPage={20000}  sort={{ field: 'name', order: 'ASC' }}    formClassName={classes.two_three_input}>
              <SelectInput optionText="name" validate={[required()]}  />
            </ReferenceInput>
            <TextInput source="city" label="Bill City" validate={[required()]} formClassName={classes.last_three_input} />


            <TextInput source="add1" label="Bill Address 1" validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="add2" label="Bill Address 2" formClassName={classes.two_three_input} />
            <TextInput source="add3" label="Bill Address 3" formClassName={classes.last_three_input} />

            <TextInput source="pincode" label="Bill Pincode" validate={[required()]} formClassName={classes.one_three_input} />


            <TextInput source="cpName1" label="Bill Contact Person 1" formClassName={classes.two_three_input} />
            <TextInput source="stateCode" label=" State Code" validate={[required()]}  formClassName={classes.last_three_input} />

            <div style={{ width: "100%" }}><h4>Shipping Address Detail</h4></div>
            <div style={{ width: "100%" }}><hr /></div>
            <TextInput source="shipState" label="Ship State" validate={[required()]} formClassName={classes.one_three_input} />
            <ReferenceInput label="Ship  Area" source="shipAreaId" reference="areas" perPage={20000}  sort={{ field: 'name', order: 'ASC' }}formClassName={classes.two_three_input}>
              <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>
            <TextInput source="shipCity" label="Ship City" validate={[required()]} formClassName={classes.last_three_input} />


            <TextInput source="shipAdd1" label="Ship Address 1" validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="shipAdd2" label="Ship Address 2" formClassName={classes.two_three_input} />
            <TextInput source="shipAdd3" label="Ship Address 3" formClassName={classes.last_three_input} />

            <TextInput source="shipPincode" label="Ship Pincode" validate={[required()]} formClassName={classes.one_three_input} />


            <TextInput source="shipCpName1" label="Ship Contact Person 1" formClassName={classes.two_three_input} />
            <TextInput source="cpMob1" label="Contact Person 1 Mobile" formClassName={classes.last_three_input} />

            <TextInput source="cpRes1" label="Contact Person 1 ResiID" formClassName={classes.one_three_input} />
            <TextInput source="cpName2" label="Contact Person 2" formClassName={classes.two_three_input} />
            <TextInput source="cpMob2" label="Contact Person 2 Mobile" formClassName={classes.last_three_input} />

            <TextInput source="cpRes2" label="Contact Person 2 ResiID" formClassName={classes.one_three_input} />
            <TextInput source="cpName3" label="Contact Person 3" formClassName={classes.two_three_input} />
            <TextInput source="cpMob3" label="Contact Person 3 Mobile" formClassName={classes.last_three_input} />

            <TextInput source="cpRes3" label="Contact Person 3 ResiID" formClassName={classes.one_three_input} />
            <TextInput source="cpName4" label="Contact Person 4" formClassName={classes.two_three_input} />
            <TextInput source="cpMob4" label="Contact Person 4 Mobile" formClassName={classes.last_three_input} />

            <TextInput source="cpRes4" label="Contact Person 4 ResiID" formClassName={classes.one_three_input} />
            <TextInput source="cpName5" label="Contact Person 5" formClassName={classes.two_three_input} />
            <TextInput source="cpMob5" label="Contact Person 5 Mobile" formClassName={classes.last_three_input} />

            <TextInput source="cpRes5" label="Contact Person 5 ResiID" formClassName={classes.one_three_input} />
            <ReferenceInput label="Handle By" source="employeeId" reference="employees" formClassName={classes.two_three_input}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" validate={[required()]} formClassName={classes.two_three_input} />
            </ReferenceInput>


            <ReferenceInput label="Tax Type" perPage={5000} source="taxtTypeID" filter={{ type: 'TT' }} reference="lookups" formClassName={classes.last_three_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput label="Tax Percentage" source="taxPercentage" formClassName={classes.one_three_input} />

            <TextInput source="shipStateCode" label="Ship State Code" validate={[required()]} formClassName={classes.two_three_input} />
            <BooleanInput source="isActive" label="Active" initialValue={true} formClassName={classes.last_three_input} />

          </FormTab>
          <FormTab label="Configration ">
            <Button onClick={() => { setIsAddTpa(true); }}
              color="secondary">
              Add Config
            </Button>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="insurer-inv-dets"
              target="insurerId">
              <Datagrid>
                <VSDeleteButton {...props} />
                {/* <TPAEditButton setHospitalDetEdit={setgridRecordEdit} /> */}
                <ReferenceField link={false} label="Claim Type" source="claimTypeId" reference="lookups" fullWidth={true} >
                  <TextField source="name" />
                </ReferenceField>
                <TextField fullWidth={true} source="tatindays" label="Tat in days" />
                <TextField fullWidth={true} source="billingPercentage" label="Billing Percentage" />
                <TextField fullWidth={true} source="billingAmount" label="Billing Amount" />
                <TextField fullWidth={true} source="professionalFee" label="Professional Fee" />
                <ReferenceField link={false} label="Incentive Type" source="incentiveType" reference="lookups" fullWidth={true} >
                  <TextField source="name" />
                </ReferenceField>
                <TextField fullWidth={true} source="incentiveAmt" label="Incentive Amt" />
                <ReferenceField link={false} label="other Charges Type" source="otherChargesType" reference="lookups" fullWidth={true} >
                  <TextField source="name" />
                </ReferenceField>
                <TextField fullWidth={true} source="otherCharges" label="Other Charges Amt" />
                <TextField fullWidth={true} source="remarks" label="Remarks" />
                <TextField fullWidth={true} source="other" label="Other" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Mou Documents">
            <div style={{ flex: 1 }}>
              <Button onClick={() => { setIsAddDocuments(true); }}>Add New</Button>

            </div>

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="insurer-docs"
              target="insurerId">
              <Datagrid>
                <CDeleteButton {...props} />
                {/* <TextField label="ITP" source="itp" />
                <TextField label="BillNo" source="billNo" /> */}
                <DateField showTime={true} label="Created Date" source="createdDate" locales="fr-FR" />
                {/* <TextField label="Document Type" source="docType" /> */}
                <ReferenceField source="typeId" reference="lookups" label="Type" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                {/* <UploadDocument onClick={(record) => { setIsEditDocumentRecord(record); setIsEditDocuments(true); }} label="Upload Document" /> */}
              </Datagrid>
            </ReferenceManyField>
          </FormTab>

          <FormTab label="Circular Documents">
            <div style={{ flex: 1 }}>
              <Button onClick={() => { setIsAddCircularDocuments(true); }}>Add New</Button>
            </div>

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="insurer-circular-docs"
              target="insurerId">
              <Datagrid>
                <CDeleteButton {...props} />
                <DateField showTime={true} label="createdDate" source="createdDate" locales="fr-FR" />
                <ReferenceField source="typeId" reference="lookups" label="Type" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <DateField label="Valid From" source="validFrom" />
                <DateField label="Valid To" source="validTo" />
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>

        </TabbedForm>


      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isaddTpa || isaddDocuments || isaddCircularDocuments} >
        {isaddTpa && <ConfigCreate
          onClose={closeEdit}
          onCancel={onCancel}
          id={props.id}
          {...props} />}
        {isaddDocuments && <DocumentsCreate
          onClose={closeEdit}
          onCancel={onCancel}
          insurerId={props.id}
          {...props} />}
        {isaddCircularDocuments && <CircularDocumentsCreate
          onClose={closeEdit}
          onCancel={onCancel}
          insurerId={props.id}
          {...props} />}  

      </Drawer>
    </React.Fragment>
  );
};
