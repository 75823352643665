import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,Create,FormDataConsumer
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import { AddressFields } from './AddressFields';
import moment from 'moment';
const AreaCreate = props => {
    const classes = useStyles({});
    const initialValues = {
       // hospitalId: props.hospitalId
    }
    const handleSubmit = (formdata) => {
        console.log(formdata);
        let m = moment(new Date());
        formdata.employeeId=parseInt(props.employeeId)
        formdata.isActive=true;
        let user = localStorage.getItem("userId");
        if (user) {
          user = parseInt(user);
        } 
        formdata.createdBy=user ;
        formdata.createdDate= m.toISOString();
        	
        apiClient.addEntiyRecord("employee-loc-dets", formdata).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <Create {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined"> 
               
                
                <FormDataConsumer>
                            {formDataProps => (
                              <AddressFields {...formDataProps} />
                            )}
                          </FormDataConsumer>
                          <ReferenceInput variant="outlined" label="Area"
                              
                              sort={{ field: 'name', order: 'ASC' }}
                              filterToQuery={searchText => ({ 'name~like': searchText })}
                                validate={required()} source="areaId" reference="areas" perPage={5000}
                                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                                fullWidth={true} >
                                
                                <AutocompleteInput validate={required()} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}  fullWidth={true} className={classes.two_4_input}/>
                            </ReferenceInput>
            </SimpleForm>
        </Create>
    );
}
export default AreaCreate;