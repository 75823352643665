import React from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    Filter,
    FunctionField
} from "react-admin";
import { Button } from "@material-ui/core";
import { useForm } from 'react-final-form';
// Filters Component
const EmployeeFilters = (props) => (
    
    <Filter>
        <ReferenceInput label="Employee" source="employeeId" reference="employees"
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })}
            variant="outlined"
            alwaysOn>
            <AutocompleteInput optionText="name" variant="outlined" />
        </ReferenceInput>
        <ReferenceInput label="State" source="stateId" reference="states" alwaysOn
            variant="outlined"
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="City" source="cityId" reference="cities" alwaysOn
            variant="outlined"
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="District" source="districtId" reference="districts" alwaysOn
            variant="outlined"
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Area" source="areaId" reference="areas" alwaysOn
            variant="outlined"
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

// Main Component
const EmployeeLookup = ({ formData, ...rest }) => {
    const form = useForm();
    return (
        <List
            {...rest}
            resource="employee-loc-dets"
            exporter={false}
            bulkActionButtons={false}
            title="Employee List"
            perPage={10000}
            hasCreate={false}
            filters={
            
                <Filter>
                <ReferenceInput label="Employee" source="employeeId" reference="employees"
                    sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                    variant="outlined"
                    alwaysOn>
                    <AutocompleteInput optionText="name" variant="outlined" />
                </ReferenceInput>
                <ReferenceInput label="State" source="stateId" reference="states" alwaysOn
                    variant="outlined"
                    sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="City" source="cityId" reference="cities" alwaysOn
                    variant="outlined"
                    sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="District" source="districtId" reference="districts" alwaysOn
                    variant="outlined"
                    sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Area" source="areaId" reference="areas" alwaysOn
                    variant="outlined"
                    sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            </Filter>
            }
        >
            <Datagrid rowClick={false}>
                {/* Action Column */}
                <FunctionField 
                    label="Action" 
                    render={record => (
                        <Button 
                            variant="contained" 
                            color="primary" 
                            size="small"
                            onClick={() => {
                                form.change("employeeId",record.employeeId)
                                rest.onSuccess && rest.onSuccess(record)

                            }
                            //    props.onSuccess && props.onSuccess(record)
                            }
                        >
                            Select
                        </Button>
                    )}
                />

                <ReferenceField source="employeeId" reference="employees" label="Employee" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="employeeId" reference="employees" label="Mobile No" link={false}>
                    <TextField source="mobileNo" />
                </ReferenceField>
                <ReferenceField source="stateId" reference="states" label="State" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="cityId" reference="cities" label="City" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="districtId" reference="districts" label="District" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="areaId" reference="areas" label="Area" link={false}>
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export default EmployeeLookup;
