import React, { useState, useCallback } from 'react';
import { Drawer } from '@material-ui/core';
import {
  Edit, TabbedForm, TextInput, DateInput, ReferenceInput, SelectInput, required, BooleanInput, FormTab, useRefresh, ReferenceManyField, ReferenceField, Datagrid, NumberInput, TextField,
  DateField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import AreaCreate from './AreaCreate';
import { makeStyles } from '@material-ui/core/styles';
import CDeleteButton from "../../common/CDeleteButton";
import Button from '@material-ui/core/Button';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 650,
  },
}));
export const EmployeeEdit = (props) => {
  let propsObj = { ...props };
  const myclasses = myStyles({});
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  const [isaddArea, setIsAddArea] = useState(false);
  const refresh = useRefresh();
   const closeEdit = () => {
    setIsAddArea(false); 
      refresh();
    }
    const onCancel = () => {
      setIsAddArea(false);
    }
    const handleClose = useCallback(() => {
      setIsAddArea(false);
    }, []);
  return (
    <>
    <Edit title="Employee Edit" {...propsObj}>
      <TabbedForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <FormTab label="Detail">
        <TextInput source="code"  fullWidth={true} validate={[required()]} />

        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <ReferenceInput label="Type" fullWidth={true} source="typeId" filter={{ type: 'ET' }} reference="lookups" >
                       <SelectInput optionText="name" variant="outlined"   />
                    </ReferenceInput>
        <ReferenceInput perPage={2000} label="User" source="userId" reference="users" fullWidth={true} validate={[required()]} sort={{ field: 'userName', order: 'ASC' }}>
          <SelectInput optionText="userName" />
        </ReferenceInput>
        <DateInput source="doj" label="Date of Joining" fullWidth={true} validate={[required()]} />
        <TextInput source="designation" label="Designation" fullWidth={true} />
        <TextInput source="mobileNo" label="Mobile No" fullWidth={true} validate={[required()]} />
        <TextInput source="altMobileNo" label="Alternate Mobile No" fullWidth={true} />
        <TextInput source="email" label="Email Address" fullWidth={true} />
        <TextInput source="add1" label="Address1" fullWidth={true} validate={[required()]} />
        <TextInput source="add2" label="Address2" fullWidth={true} />
        <TextInput source="city" label="City" fullWidth={true} />
        <TextInput source="state" label="State" fullWidth={true} />
        <TextInput source="zip" label="Pincode" fullWidth={true} />
        <TextInput source="pan" label="PAN" fullWidth={true} />
        <TextInput source="adharNo" label="Adhaar No" fullWidth={true} />
        <TextInput source="bankAcNo" label="Bank Account No" fullWidth={true} />
        <TextInput source="bankName" label="Bank Name" fullWidth={true} />
        <TextInput source="ifscCode" label="IFSC Code" fullWidth={true} />
        <DateInput source="dol" label="Date of Leaving" fullWidth={true} />
        <NumberInput source="basicSalary" label="Basic Salary" fullWidth={true} />
        <NumberInput source="monthlyTax" label="Monthly Tax" fullWidth={true} />

        <BooleanInput source="isActive" validate={[required()]} label="Active" />
      </FormTab>
      <FormTab label="Area">
            <div style={{ flex: 1 }}>
              <Button onClick={() => { setIsAddArea(true); }}>Add Area</Button>
            </div>

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="employee-loc-dets"
              target="employeeId">
              <Datagrid>
                <CDeleteButton {...props} />
                
                <ReferenceField source="stateId" reference="states" label="State" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="cityId" reference="cities" label="City" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="districtId" reference="districts" label="District" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="areaId" reference="areas" label="Area" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DateField showTime={true} label="Created Date" source="createdDate" locales="fr-FR" /> 
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
    </TabbedForm>
    </Edit>
    <Drawer
            anchor="right"
            onClose={handleClose}
            classes={{ paper: myclasses.drawerPaper, }}
            open={isaddArea} >
    {isaddArea && <AreaCreate
          onClose={closeEdit}
          onCancel={onCancel}
          employeeId={props.id}
          {...props} />}
          </Drawer>
    </>
  );
};
